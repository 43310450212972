<template>
  <div>
    <v-tabs
      grow
      show-arrows
      center-active
      v-model="tab"
      background-color="grey lighten-2"
    >
      <v-tab href="#status" v-if="isHeim">Status</v-tab>
      <v-tab href="#disciplines" v-if="isHeim">Geräte</v-tab>
      <v-tab href="#riegen" v-if="isHeim">Riegen</v-tab>
      <v-tab href="#mannschaften">Mannschaften</v-tab>
      <v-tab href="#kari" v-if="isHeim">Kampfgerichte</v-tab>
      <v-tab href="#wkbericht" v-if="isHeim">WK-Bericht</v-tab>
      <v-tab href="#pdf">PDF</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item value="status" v-if="isHeim">
        <status :e="e" :id="id" :r="r" :df="df" />
      </v-tab-item>
      <v-tab-item value="disciplines" v-if="isHeim">
        <disciplines :e="e" :df="df" :id="id" />
      </v-tab-item>
      <v-tab-item value="riegen" v-if="isHeim">
        <riegen :e="e" :df="df" :id="id" />
      </v-tab-item>
      <v-tab-item value="mannschaften">
        <mannschaften :e="e" :df="df" :id="id" />
      </v-tab-item>
      <v-tab-item value="kari" v-if="isHeim">
        <kampfrichter :e="e" :df="df" :id="id" />
      </v-tab-item>
      <v-tab-item value="wkbericht" v-if="isHeim">
        <wkbericht :e="e" :id="id" />
      </v-tab-item>
      <v-tab-item value="pdf">
        <pdf :e="e" :r="r" :df=df :id="id" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { useAuth } from '@/plugins/auth'

export default {
  name: 'vor',

  components: {
    Status: () => import('./general/status'),
    Disciplines: () => import('./vor/disciplines'),
    Riegen: () => import('./vor/riegen'),
    Mannschaften: () => import('./vor/mannschaften'),
    Kampfrichter: () => import('./vor/kampfgerichte'),
    Wkbericht: () => import('./vor/wkbericht'),
    Pdf: () => import('./vor/pdf')
  },

  setup (props, context) {
    return {
      ...useAuth(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    tab: 'status'
  }),

  computed: {
    isHeim () {
      return this.isMaster.value || this.isAdmin(this.id) || this.e.teams?.filter(t => t.home).some(t => this.isTeam(t.team._id))
    }
  }
}
</script>

<style scoped>

</style>
